import { fromUnixTime, isAfter } from 'date-fns';
import { default as React } from 'react';
import { connect } from 'react-redux';
import useWindowSize from '../../hooks/useViewport';
import { isDesktop as isBrowserDesktop } from '../../helpers/responsiveHelpers/responsiveHelpers';

import { doNewSearchViaUrl, setFilterParam, newSearch } from '../../actions/searchActions';
import { SearchMask } from '../../components/SearchFilters/SearchMask';
import { SearchMaskContainer } from '../../components/SearchFilters/SearchMaskContainer';
import { ZoneAndCompanyInputSelection } from '../../components/SearchFilters/ZoneAndCompanyInputSelection';
import RecommendationZones from '../../components/RecommendationZones/RecommendationZones';
import { Shutdown } from '../../components/Shutdown/Shutdown';
import { CardSlider } from '../../components/Card/CardSlider';
import { Card } from '../../components/Card/Card';
import { Button } from '../../components/Button/Button';
import { getCurrencyString } from '../../components/PriceString';
import { config } from '../../config';
import { eventTracker } from '../../helpers/EventTracker/EventTracker';
import { getFilter, transformOptions } from '../../helpers/searchHelpers';
import { getDiscountAboveThreshold } from '../../helpers/priceHelpers';
import { isBlank } from '../../helpers/string';
import { __ } from '../../helpers/TranslationService';
import { IState } from '../../reducers';
import { DreamDealHome } from './DreamDealHome';
import { SEOHome } from './SEOHome';
import { TopOffer } from './TopOffer';
import { numberFormatter } from '../../helpers/TranslationService';
import {
    HomePageContainer,
    SectionContainer,
    SectionContainerTitle,
    SectionContainerSubTitle,
    SectionContainerButton,
    RecommendationZonesWrapper,
    ContainerWrapper,
    Cruise1stPackage,
    Cruise1stPackageTitle,
    Cruise1stPackageText,
    CruiseLineLogo,
    CruiseLineLogoItem,
} from './styles';

import { TrustBadges } from '../../components/TrustBadges/TrustBadges';
import styled from '@emotion/styled';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { useHomePageFilters } from './useHomePageFilters';
import { CardTeaserSlider } from '../../components/Card/CardTeaserSlider';
import { cardTeaserSliderEventTracker } from './eventTrackerHelpers';

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const organizationSchemaData = (url: string, logo: string) => ({
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Dreamlines',
    url,
    logo,
});

const getFullUrl = (portalId: string): string => `https://www.${portalId}`;

export const dateTresholdDate = (availableUntil: string | number): Date => {
    const dateTresholdUnix =
        typeof availableUntil === 'number' ? availableUntil : parseInt(availableUntil, 10);
    if (isNaN(dateTresholdUnix)) {
        throw new Error(`Unexpected date value: ${availableUntil}`);
    }
    return fromUnixTime(dateTresholdUnix);
};

const SeoWrapper = styled.section`
    @media (max-width: ${BreakpointsEnum.md}) {
        margin: 30px 16px;
    }
`;

const HomePageClass = (props: Props) => {
    const { filterProps, setFilter, setFilterArray, onApplyFilterProps } = useHomePageFilters({
        updateFilterStoreState: props.setFilterParam,
        newSearch: props.newSearch,
    });

    const homePageData = props.homePage?.homePage;

    const openOffer = (GaEvent, url: string): void => {
        eventTracker.trackG4Event('homepage_button_click', GaEvent);
        window.location.href = url;
    };

    const { zoneNids, companyNids, topCompanies, topZones } = props.search.searchFacets;
    const { dateMax, dateMin, zone, company } = getFilter(props.search, filterProps);
    const side = 'right';

    const shouldShowDreamDeal =
        homePageData?.dreamDeal?.status &&
        isAfter(dateTresholdDate(homePageData.dreamDeal.availableUntil), new Date());

    const numResults = props.search.searchFacets.numResults;
    const disableButton = !numResults;
    const loadingButton = !numResults && numResults !== 0;
    const numberOfFiltersUsed = props.search.filterParamsList.length;
    const showSearchBtn = Boolean(numberOfFiltersUsed);

    const searchMaskProps = {
        searchFormId: 'searchForm',
        pageType: 'Home Page',
        title:
            (Number.isInteger(numResults) ? numberFormatter().format(numResults) + ' ' : '') +
            __('Search cruise', 'dreamlines'),
        dateMax,
        dateMin,
        onDateMinChange: setFilter('dateMin'),
        onDateMaxChange: setFilter('dateMax'),
        sticky: false,
        isLoading: loadingButton,
        doNewSearchViaUrl: props.doNewSearchViaUrl,
        showSearchBtn: showSearchBtn,
        isDisabled: disableButton,
        onDateChange: setFilterArray(['dateMin', 'dateMax']),
        numberOfFiltersUsed: numberOfFiltersUsed,
        languageCode: props.pageData.appConfig.languageCode,
        countryCode: props.pageData.appConfig.countryCode,
        headline: __('Great cruises, many discounts (mobile)', 'dreamlines'),
        headlineDesktop: __('Great cruises, many discounts', 'dreamlines'),
        relativePositioning: shouldShowDreamDeal || homePageData?.topOffer?.status,
        buttonClickEventData: {
            button_type: 'home_search',
            company_name:
                companyNids.find((companyObj) => companyObj?.id === company)?.title || company,
            destination_name: zoneNids.find((zoneObj) => zoneObj?.id === zone)?.title || zone,
        },
    };

    const zoneAndCompanyInputProps = {
        zoneNids,
        companyNids,
        topCompanies,
        topZones,
        zone,
        company,
        transformOptions: transformOptions,
        onCompanyChange: setFilter('company'),
        onZoneChange: setFilter('zone'),
        noPaddingTop: false,
    };

    const baseUrl = getFullUrl(props.portalId);
    const { width } = useWindowSize();
    const isDesktop = isBrowserDesktop(width);

    const hasTopTeasers =
        homePageData?.topTeasers?.status && !!homePageData?.topTeasers?.items?.length;

    const hasDestination =
        homePageData?.destinationGallery?.status &&
        !!homePageData?.destinationGallery?.items?.length;

    const hasThemeCruiseTeasers =
        homePageData?.themeCruiseTeasers?.status &&
        !!homePageData?.themeCruiseTeasers?.items?.length;

    const hasCruiseLineLogos =
        homePageData?.cruiseLineLogos?.status && !!homePageData?.cruiseLineLogos?.items?.length;

    const hasSEO = homePageData?.seo?.section && homePageData?.seo?.section?.status;

    const zoneTabsData = homePageData.zoneTabs || [];

    const themeTopicBanner = homePageData?.themeTopicBanner || null;
    const { status, images, headline, subline, buttonText, buttonLink } = themeTopicBanner || {};

    const hasPackage = themeTopicBanner || status;

    const packageImage = isDesktop ? images?.desktop : images?.mobile;

    const { discountThreshold } = props.pageData;

    if (!homePageData) {
        return <Shutdown />;
    }

    const renderDreamDeal = () => {
        if (shouldShowDreamDeal) {
            return (
                <>
                    <DreamDealHome
                        side={side}
                        flex="center"
                        currency={props.pageData.appConfig.defaultCurrency}
                        dreamDeal={homePageData.dreamDeal}
                    />

                    {!isBlank(homePageData.dreamDeal?.buttonLink) && (
                        <div
                            className="dreamdeal-url"
                            onClick={(e) =>
                                openOffer(
                                    {
                                        button_type: 'home_dreamdeal',
                                        cruise_id: homePageData.dreamDeal?.cruiseId,
                                        description: homePageData.dreamDeal?.description,
                                    },
                                    homePageData.dreamDeal?.buttonLink,
                                )
                            }
                        ></div>
                    )}
                </>
            );
        }

        if (homePageData?.topOffer?.status) {
            return (
                <>
                    <TopOffer
                        side={side}
                        flex="start"
                        currency={props.pageData.appConfig.defaultCurrency}
                        topOffer={homePageData.topOffer}
                    />
                    {!isBlank(homePageData.topOffer.companyImage) && (
                        <div
                            className="topoffer-url"
                            onClick={(e) => {
                                openOffer(
                                    {
                                        button_type: 'home_banner',
                                        headline: homePageData.topOffer?.headline,
                                    },
                                    homePageData.topOffer.buttonLink,
                                );
                            }}
                        ></div>
                    )}
                </>
            );
        }

        return null;
    };

    return (
        <HomePageContainer className="home-page-container">
            <SearchMaskContainer
                side={side}
                hasDreamDeal={shouldShowDreamDeal || homePageData?.topOffer?.status}
                image={homePageData?.stage?.backgroundImage}
            >
                <SearchMask
                    isHomePage
                    {...searchMaskProps}
                    pageData={props.pageData}
                    onAfterApplyClick={onApplyFilterProps}
                    router={props.router}
                    testSelectorName="home-page-headline"
                >
                    <ZoneAndCompanyInputSelection
                        {...zoneAndCompanyInputProps}
                        autoSetFocus={isDesktop}
                    />
                </SearchMask>
                <TrustBadges portalId={props.portalId} className="trust-badges" />
                {renderDreamDeal()}
            </SearchMaskContainer>
            {hasTopTeasers && (
                <CardTeaserSlider
                    isDesktop={isDesktop}
                    items={homePageData.topTeasers.items}
                    currency={props.pageData.brandConfig.defaultCurrency}
                    eventTracker={cardTeaserSliderEventTracker}
                />
            )}
            <ContainerWrapper>
                <RecommendationZonesWrapper>
                    <RecommendationZones
                        title={__('Weekly Top Picks', 'dreamlines')}
                        data={zoneTabsData}
                    />
                </RecommendationZonesWrapper>
                {hasDestination && (
                    <SectionContainer>
                        <SectionContainerTitle>
                            {homePageData.destinationGallery.headline}
                        </SectionContainerTitle>
                        <SectionContainerSubTitle>
                            {homePageData.destinationGallery.subline}
                        </SectionContainerSubTitle>
                        <CardSlider>
                            {homePageData.destinationGallery.items.slice(0, 4).map((slide) => {
                                const { name, image, url, description, price, discount } = slide;
                                return (
                                    <Card
                                        key={name}
                                        imageUrl={image}
                                        url={url}
                                        title={name}
                                        description={description}
                                        price={
                                            Number(price) > 0
                                                ? getCurrencyString(
                                                      price,
                                                      props.pageData.brandConfig.defaultCurrency,
                                                  )
                                                : null
                                        }
                                        discount={getDiscountAboveThreshold(
                                            discount,
                                            discountThreshold,
                                        )}
                                    />
                                );
                            })}
                        </CardSlider>
                        <SectionContainerButton>
                            <Button
                                as="a"
                                href={homePageData.destinationGallery.buttonLink}
                                color="secondary"
                                size="small"
                            >
                                {homePageData.destinationGallery.buttonText}
                            </Button>
                        </SectionContainerButton>
                    </SectionContainer>
                )}
                {hasPackage && (
                    <Cruise1stPackage style={{ backgroundImage: `url(${packageImage})` }}>
                        <Cruise1stPackageTitle>{headline}</Cruise1stPackageTitle>
                        <Cruise1stPackageText>{subline}</Cruise1stPackageText>
                        <Button as="a" href={buttonLink} size="medium" fullWidth={!isDesktop}>
                            {buttonText}
                        </Button>
                    </Cruise1stPackage>
                )}
                {hasThemeCruiseTeasers && (
                    <SectionContainer>
                        <SectionContainerTitle>
                            {homePageData.themeCruiseTeasers.headline}
                        </SectionContainerTitle>
                        <SectionContainerSubTitle>
                            {homePageData.themeCruiseTeasers.subline}
                        </SectionContainerSubTitle>
                        <CardSlider>
                            {homePageData.themeCruiseTeasers.items.slice(0, 4).map((slide) => {
                                const { label, text, image, url, price, discount } = slide;
                                return (
                                    <Card
                                        key={label}
                                        imageUrl={image}
                                        url={url}
                                        title={label}
                                        description={text}
                                        price={
                                            Number(price) > 0
                                                ? getCurrencyString(
                                                      price,
                                                      props.pageData.brandConfig.defaultCurrency,
                                                  )
                                                : null
                                        }
                                        discount={getDiscountAboveThreshold(
                                            discount,
                                            discountThreshold,
                                        )}
                                    />
                                );
                            })}
                        </CardSlider>
                        <SectionContainerButton>
                            <Button
                                as="a"
                                href={homePageData.themeCruiseTeasers.buttonLink}
                                color="secondary"
                                size="small"
                            >
                                {homePageData.themeCruiseTeasers.buttonText}
                            </Button>
                        </SectionContainerButton>
                    </SectionContainer>
                )}
                {hasCruiseLineLogos && (
                    <SectionContainer>
                        <CruiseLineLogo>
                            {homePageData.cruiseLineLogos.items.slice(0, 8).map((slide) => {
                                const { text, image, url } = slide;
                                return (
                                    <CruiseLineLogoItem href={url} key={text}>
                                        <img src={image} alt={text} />
                                    </CruiseLineLogoItem>
                                );
                            })}
                        </CruiseLineLogo>
                        <SectionContainerButton>
                            <Button
                                as="a"
                                href={homePageData.cruiseLineLogos.buttonLink}
                                color="secondary"
                                size="small"
                            >
                                {homePageData.cruiseLineLogos.buttonText}
                            </Button>
                        </SectionContainerButton>
                    </SectionContainer>
                )}
            </ContainerWrapper>

            {hasSEO && (
                <SeoWrapper>
                    <SEOHome content={homePageData?.seo?.section?.text} />
                </SeoWrapper>
            )}

            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(
                        organizationSchemaData(
                            baseUrl,
                            `${config.images}/${props.portalId}/logo.svg`,
                        ),
                    ),
                }}
            />
        </HomePageContainer>
    );
};

const mapStateToProps = (state: IState) => ({
    search: state.search,
    router: state.router,
    homePage: state.homePage,
    pageData: state.pageData,
    portalId: state.pageData.appConfig.portalId,
});

const mapDispatchToProps = {
    setFilterParam,
    newSearch: newSearch as any,
    doNewSearchViaUrl,
};

export const HomePage = connect(mapStateToProps, mapDispatchToProps)(HomePageClass);

export default HomePage;
