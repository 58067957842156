import styled from '@emotion/styled';
import { BreakpointsEnum } from '../../helpers/breakpoints';

export const HomePageContainer = styled.div`
    background-color: ${({ theme }) => theme.background.page};
`;

export const SectionContainer = styled.section`
    margin-bottom: 120px;
`;

export const SectionContainerTitle = styled.h2`
    font-size: 28px;
    line-height: 44px;
    font-weight: 700;
    text-align: center;
    margin: 0 16px 8px;
`;

export const SectionContainerSubTitle = styled.p`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0 16px 24px;
`;

export const SectionContainerButton = styled.div`
    text-align: center;
    @media (min-width: ${BreakpointsEnum.md}) {
        margin-top: 60px;
    }
`;

export const RecommendationZonesWrapper = styled.div`
    margin-top: 0px;
    margin-bottom: 90px;
    @media (min-width: ${BreakpointsEnum.md}) {
        margin-top: 80px;
        margin-bottom: 120px;
    }
`;

export const ContainerWrapper = styled.div`
    margin-right: auto;
    margin-left: auto;
    @media (min-width: ${BreakpointsEnum.sm}) {
        width: 750px;
    }
    @media (min-width: ${BreakpointsEnum.md}) {
        width: 970px;
    }
    @media (min-width: ${BreakpointsEnum.lg}) {
        width: 1170px;
    }
`;

export const Cruise1stPackage = styled.div`
    min-height: 277px;
    padding: 25px;
    margin: 0 16px 90px;
    border-radius: 16px;
    background-position: center;
    background-size: cover;
    @media (min-width: ${BreakpointsEnum.sm}) {
        min-height: 304px;
        margin: 0 0 120px;
        padding: 60px 48px 52px 48px;
    }
`;

export const Cruise1stPackageTitle = styled.h2`
    color: ${({ theme }) => theme.colors.white[100]};
    font-size: 28px;
    font-weight: 700;
    line-height: 44px;
    margin-top: 16px;
    margin-bottom: 8px;
`;

export const Cruise1stPackageText = styled.p`
    color: ${({ theme }) => theme.colors.white[100]};
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const CruiseLineLogo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px;
    margin-bottom: 40px;
    @media (min-width: ${BreakpointsEnum.sm}) {
        justify-content: space-evenly;
        gap: 80px;
        margin-bottom: 0;
    }
`;

export const CruiseLineLogoItem = styled.a`
    display: block;
    > img {
        max-width: 130px;
        max-height: 60px;
        @media (min-width: ${BreakpointsEnum.sm}) {
            max-width: 180px;
        }
    }
`;
